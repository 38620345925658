body {
    background-color: #f8f8f8;
  }
  
  .card {
    background-color: white;
    width: 600px;
    height: 400px;
    position: absolute;
    left: 50%;
    top: 30%;
    margin-bottom: 3px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
    border-color: white;
    border-style: solid;
    border-width: 0px 120px 0px 120px;
    transform: translate(-50%, -30%);
  }
  
  .wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
    transform: translate(-50%, -50%);
  }
  
  .block {
    height: 92px;
  }
  
  .break {
    height: 80px;
  }
  
  .titlebreaka {
    height: 42px;
  }
  
  .titlebreakb {
    height: 38px;
  }
  
  .left {
    width: 180px;
    height: 92px;
    float: left;
  }
  
  .right {
    width: 420px;
    height: 92px;
    float: right;
    text-align: right;
  }
  
  .bottom {
    width: 700px;
    text-align: center;
  }
  
  @font-face {
    font-family: "Neutral";
    src: url("../public/fonts/neutral.otf") format("opentype");
  }
  
  .details {
    font-family: "Neutral", Arial, Helvetica, sans-serif;
    font-size: 16px;
  }
  
  .name {
    font-family: "Neutral", Arial, Helvetica, sans-serif;
    font-size: 26px;
    margin: 0px;
    padding: 0px;
  }
  
  a:link {
    color: #000000;
    border-bottom: 1px dashed;
    text-decoration: none;
  }  